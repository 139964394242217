html,
body {
  margin: 0;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f6f3 !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mui-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.gm-style-iw-t {
  display: none;
}
.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  color: #ffffff !important;
}
.MuiPickersLayout-root {
  display: flex !important;
  flex-direction: column;
}
.MuiDataGrid-filler {
  height: 6px !important;
}
/* .MuiPickersLayout-contentWrapper {
  width: 210px;
} */
.MuiMultiSectionDigitalClock-root {
  justify-content: space-evenly;
}
.MuiMultiSelectionDigitalClock-root {
  justify-content: space-evenly;
  display: flex;
  justify-content: center;
}
.MuiMultiSectionDigitalClock-root > ul {
  width: 50%;
}
.MuiMultiSectionDigitalClock-root > ul > li {
  font-size: 14px !important;
  margin: 0;
  border-bottom: 1px solid #eeece7;
  height: 41px;
}
.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item:first-of-type {
  margin: 0 !important;
  padding-top: 4px;
}
.MuiDialogActions-root > button {
  background-color: #ebf155;
  color: #23222d;
  border-radius: 20px;
  padding: 4px 12px;
}
.MuiDialogActions-root > button:hover {
  color: #ebf155;
  background-color: #23222d;
  border-radius: 20px;
  padding: 4px 12px;
}
.MuiMultiSectionDigitalClock-root > ul {
  width: 105px;
  text-align: center;
}
.MuiMultiSectionDigitalClock-root > ul > li {
  font-family: "Manrope";
  font-size: 18px;
  line-height: 32px;
  width: 105px;
}
.MuiMultiSectionDigitalClock-root > ul > li:hover {
  background-color: #f8f8f8;
}

.MuiMultiSectionDigitalClock-root > ul > .Mui-selected {
  background-color: #c0d4e4;
  color: black;
}

.MuiMultiSectionDigitalClock-root > ul > .Mui-selected:hover {
  background-color: #ebf155;
  color: black;
}

.tfaModal input {
  width: 28px;
  height: 32px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.tfaModal input:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}

.discardedText {
  color: #282828 !important;
  opacity: 0.5;
}

.css-fdp768-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.collectionTable {
  color: white;
  font-size: 14px;
  font-family: "Manrope";
  height: calc(50vh - 72px);
  width: 450px;
  border-spacing: 0;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.collectionTable thead {
  position: sticky;
  top: 0;
  display: block;
  z-index: 1;
}
.collectionTable thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.collectionTable tbody {
  position: relative;
  display: block;
  z-index: 0;
}
.collectionTable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.collectionTable td,
th {
  padding: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  overflow: visible;
  &:first-child {
    width: 200px;
    position: sticky;
    left: 0;
    z-index: 2;
    padding-left: 20px !important;
    text-align: left;
  }
}

.graphCard::-webkit-scrollbar-track {
  background: rgba(70, 74, 83, 1);
  border-radius: 0px;
}

.graphCard::-webkit-scrollbar-thumb {
  background-color: #dfdfc9;
  border-radius: 0px;
}

.collectionTable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.collectionTable::-webkit-scrollbar-track {
  background: rgba(70, 74, 83, 1);
  border-radius: 6px;
}

/* Handle */
.collectionTable::-webkit-scrollbar-thumb {
  background: #ebf155;
  border-radius: 6px;
}

/* Handle on hover */
.collectionTable::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(70, 74, 83, 1);
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(180, 199, 214, 1);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

.MuiPickersYear-yearButton.Mui-selected {
  background-color: #ebf155 !important;
}
